// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

//import "jquery"
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
Rails.start()
Turbolinks.start()
ActiveStorage.start()
//require("jquery")
window.jQuery = $;
window.$ = $;
var jQuery = require('jquery')
// include jQuery in global and window scope (so you can access it globally)
// in your web browser, when you type $('.div'), it is actually refering to global.$('.div')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;
import $ from 'jquery';
window.bootstrap = require('template/bootstrap.bundle.js');


import 'owl.carousel/dist/assets/owl.carousel.css';
//import 'owl.carousel.scss';
import 'owl.carousel';

import "template/banana"
import "main"
import "modernizr.min"
const images = require.context('../images', true)
